
import { defineComponent, computed } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TasksEmpty from '@/components/pages/tasks/index/TasksEmpty.vue'
import { useModes } from '@/compositions/modes'
import TasksFaq from '@/components/pages/tasks/index/TasksFaq.vue'
import TasksHalfEmpty from '@/components/pages/tasks/index/TasksHalfEmpty.vue'

export default defineComponent({
  components: {
    TasksHalfEmpty,
    TasksFaq,
    TasksEmpty,
    PageContent,
  },
  setup() {
    const { isEmptyMode, isEmptyHalfMode } = useModes()
    const computedTitle = computed(() => isEmptyMode.value ? 'New board' : 'Personal')

    return {
      computedTitle,
      isEmptyHalfMode,
      isEmptyMode,
    }
  },
})
