import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tasks_empty = _resolveComponent("tasks-empty")!
  const _component_tasks_faq = _resolveComponent("tasks-faq")!
  const _component_tasks_half_empty = _resolveComponent("tasks-half-empty")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    title: _ctx.computedTitle,
    "no-scroll": ""
  }, {
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "page-content-top-right": _withCtx(() => [
      _renderSlot(_ctx.$slots, "page-content-top-right")
    ]),
    default: _withCtx(() => [
      (_ctx.isEmptyMode)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_tasks_empty, { class: "mb-16" }),
            _createVNode(_component_tasks_faq)
          ], 64))
        : (_ctx.isEmptyHalfMode)
          ? (_openBlock(), _createBlock(_component_tasks_half_empty, { key: 1 }))
          : (_openBlock(), _createBlock(_component_router_view, { key: 2 }))
    ]),
    _: 3
  }, 8, ["title"]))
}